@import 'mantine';
.section {
  position: relative;
  background: radial-gradient(
      54.64% 67.3% at 51.55% 103.87%,
      #0039a4 26.5%,
      rgba(0, 57, 164, 0) 100%
    ),
    radial-gradient(
      60.33% 54.31% at 51.55% 100%,
      #0039a4 0%,
      rgba(0, 57, 164, 0) 100%
    ),
    radial-gradient(
      75.87% 37.69% at 51.55% 100%,
      #001742 0%,
      rgba(0, 23, 66, 0) 64%
    ),
    linear-gradient(0deg, #001742, #001742);
}

.page {
  height: 100vh;
  position: relative;
  color: white;

  h1 {
    margin-bottom: rem(15);
  }

  p {
    margin: 0;
    text-align: center;
  }
}

.backgroundText {
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  user-select: none;

  span {
    font-size: rem(280);
    margin-bottom: rem(120);
    opacity: 0.08;

    @include larger-than($mantine-breakpoint-desktop-sm) {
      font-size: rem(486);
      margin-bottom: rem(120);
    }
  }
}
